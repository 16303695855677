import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

// Users generally will get to the priority shifts page by clicking on the
// priority shifts callout, so an empty state is unlikely to be shown.
// However, we might still show an empty state if the user navigates to
// the priority shifts page directly.
export function PriorityShiftsEmptyState() {
  return (
    <Stack sx={{ flex: 1 }} justifyContent="center" spacing={5}>
      <Title semibold variant="h4" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
        There are no priority shifts available
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        We&apos;ll send you a notification when new priority shifts are available
      </Text>
    </Stack>
  );
}
