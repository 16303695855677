import {
  calculateGeoDistanceInMiles,
  convertFromGoogleMapsLocation,
  type GeoLocation,
} from "@src/appV2/Location";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useGeoLocationFromUrlParams } from "../../GeoLocation/useGeoLocationFromUrlParams";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";

const LOCATION_FILTER_CHANGE_DEBOUNCE_TIME = 250;
const LOCATION_FILTER_CHANGE_DISTANCE_THRESHOLD = DEFAULT_DISTANCE_IN_MILES_FILTER * 0.25;

export function useLocationFilter(onLocationChange?: (coordinates: GeoLocation) => void) {
  const [locationFilter, setLocationFilter] = useState<GeoLocation | undefined>(undefined);
  const { onChangeGeoLocation } = useGeoLocationFromUrlParams();

  const onChangeLocationFilter = useCallback(
    (mapCenter: google.maps.LatLngLiteral) => {
      const newLocation = convertFromGoogleMapsLocation(mapCenter);
      setLocationFilter(newLocation);
      onLocationChange?.(newLocation);
      onChangeGeoLocation(newLocation);
    },
    [onLocationChange, onChangeGeoLocation]
  );

  const debouncedChangeLocationFilter = useMemo(
    () => debounce(onChangeLocationFilter, LOCATION_FILTER_CHANGE_DEBOUNCE_TIME),
    [onChangeLocationFilter]
  );

  function onChangeLocationFilterAfterDistance(mapCenter?: google.maps.LatLng) {
    if (!locationFilter || !mapCenter) {
      return;
    }

    const movedDistance = calculateGeoDistanceInMiles(
      locationFilter,
      convertFromGoogleMapsLocation(mapCenter.toJSON())
    );

    // Only update the map center if the user has moved
    // far enough away, as soon as 1/4 of the distance filter is passed.
    // This is to prevent the map from being updated too often,
    // which can cause performance issues, and a jittery experience
    // when the user is just panning around.
    if (movedDistance > LOCATION_FILTER_CHANGE_DISTANCE_THRESHOLD) {
      debouncedChangeLocationFilter(mapCenter.toJSON());
    }
  }

  return { locationFilter, onChangeLocationFilter, onChangeLocationFilterAfterDistance };
}
