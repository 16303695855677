import { type GeoLocation } from "@src/appV2/Location";
import { useIsShiftBlocksEnabled } from "@src/appV2/redesign/Shift/Block/useIsShiftBlocksEnabled";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import type { QueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { uniqBy } from "lodash";
import { useCallback, useMemo } from "react";

import { getIncludedOpenShiftsData } from "../../Shift/Open/getIncludedOpenShiftsData";
import { type GetOpenShiftsResponse } from "../../Shift/Open/useGetOpenShifts";
import {
  invalidateGetPaginatedOpenShiftsByArea,
  useGetPaginatedOpenShiftsByArea,
} from "../../Shift/Open/useGetPaginatedOpenShiftsByArea";
import { isPriorityShift } from "../../Shift/Priority/isPriorityShift";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { useGetMultiLicenseAggregatedBlocks } from "../Blocks/useGetMultiLicenseAggregatedBlocks";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { useUrgentShiftsData } from "../UrgentShifts/useUrgentShiftsData";
import { getMapViewWorkplaces } from "./getMapViewWorkplaces";
import { sortMapViewWorkplaces } from "./sortMapViewWorkplaces";
import { useGetOnCallWorkplaces } from "./useGetOnCallWorkplaces";

interface UseMapWorkplacesDataProps {
  geoLocation?: GeoLocation;
  shouldFetchUrgentShifts?: boolean;
}

export async function invalidateMapViewWorkplacesData(queryClient: QueryClient): Promise<void> {
  await invalidateGetPaginatedOpenShiftsByArea(queryClient);
}

/**
 * Fetches open shifts and workplaces data and returns a sorted list of workplaces
 * that have open shifts within the user's distance filter.
 *
 * Using pagination to fetch data to prevent previous results from being discarded.
 * This way, workplaces already fetched will remain visible when the user
 * moves the map around.
 */
export function useMapViewWorkplacesData(props: UseMapWorkplacesDataProps) {
  const { geoLocation, shouldFetchUrgentShifts = true } = props;

  const isShiftBlocksEnabled = useIsShiftBlocksEnabled();

  const workerGeoLocation = useWorkerGeoLocation();
  const coordinates = geoLocation ?? workerGeoLocation ?? { latitude: 0, longitude: 0 };

  const areQueriesEnabled = coordinates.latitude !== 0 && coordinates.longitude !== 0;

  const filters = useShiftDiscoveryUserFiltersContext();

  const dates =
    filters.dates.length > 0
      ? filters.dates.map((dateString) => parseISO(dateString))
      : getShiftDiscoveryDefaultDateRange();

  const {
    data: paginatedOpenShiftsData,
    isFetching: isFetchingOpenShiftsData,
    isError: isErrorOpenShiftsData,
    isSuccess: isSuccessOpenShiftsData,
    fetchNextPage: fetchNextOpenShiftsPage,
  } = useGetPaginatedOpenShiftsByArea(
    {
      initialArea: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        radiusInMiles: filters.distance,
      },
      dates,
      include: ["workplace"],
    },
    {
      enabled: areQueriesEnabled,
    }
  );

  // The open shifts data doesn't include all urgent shifts, so we need to fetch them separately
  const { shifts: urgentShifts } = useUrgentShiftsData({
    enabled: areQueriesEnabled && shouldFetchUrgentShifts,
  });

  const filteredUrgentShifts = filterOpenShifts({ shifts: urgentShifts }, filters);

  const { data: blockShifts } = useGetMultiLicenseAggregatedBlocks(
    {
      maxDistanceMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
    },
    {
      enabled: areQueriesEnabled && isShiftBlocksEnabled,
    }
  );

  const onCallWorkplaceIds = useGetOnCallWorkplaces();

  const openShiftsData: GetOpenShiftsResponse | undefined = useMemo(() => {
    if (!paginatedOpenShiftsData) {
      return {
        data: [],
        included: [],
      };
    }

    const openShifts = paginatedOpenShiftsData.pages.flatMap((page) => page.data);

    return {
      data: uniqBy(openShifts, (shift) => shift.id),
      included: paginatedOpenShiftsData.pages.flatMap((page) => page.included),
    };
  }, [paginatedOpenShiftsData]);

  const filteredShifts = filterOpenShifts({ shifts: openShiftsData?.data ?? [] }, filters);
  const sortedMapViewWorkplaces = useMemo(() => {
    const { workplacesMap } = getIncludedOpenShiftsData({
      ...openShiftsData,
      data: filteredShifts,
    });

    const mapViewWorkplaces = getMapViewWorkplaces({
      openShifts: filteredShifts,
      openShiftWorkplacesMap: workplacesMap,
      onCallWorkplaceIds,
    });

    return sortMapViewWorkplaces(mapViewWorkplaces);
  }, [openShiftsData, onCallWorkplaceIds, filteredShifts]);

  const fetchMore = useCallback(
    (coordinates: GeoLocation, distanceInMiles: number = DEFAULT_DISTANCE_IN_MILES_FILTER) => {
      const area = { ...coordinates, radiusInMiles: distanceInMiles };
      if (!isFetchingOpenShiftsData) {
        void fetchNextOpenShiftsPage({ pageParam: area });
      }
    },
    [fetchNextOpenShiftsPage, isFetchingOpenShiftsData]
  );

  const priorityShifts = filteredShifts.filter((shift) =>
    isPriorityShift({
      priorityTill: shift.attributes.priorityTill,
      window: shift.attributes.window,
    })
  );

  const urgentShiftsCount = shouldFetchUrgentShifts
    ? filteredUrgentShifts.length
    : filteredShifts.filter((shift) => shift.attributes.isUrgent).length;

  const lastAreaFilterUsed = paginatedOpenShiftsData?.pages.at(-1)?.area;
  const geoLocationFilter: GeoLocation = lastAreaFilterUsed ?? coordinates;
  const distanceFilter: number =
    lastAreaFilterUsed?.radiusInMiles ?? DEFAULT_DISTANCE_IN_MILES_FILTER;

  return {
    data: {
      mapViewWorkplaces: sortedMapViewWorkplaces,
      urgentShiftsCount,
      priorityShiftsCount: priorityShifts.length,
      blockShiftsCount: blockShifts?.data.length ?? 0,
      distanceFilter,
      geoLocationFilter,
    },
    isLoading: isFetchingOpenShiftsData,
    isError: isErrorOpenShiftsData,
    isSuccess: isSuccessOpenShiftsData,
    fetchMore,
  };
}
