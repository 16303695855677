import { isDefined } from "@clipboard-health/util-ts";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location/deviceLocation";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import { convertToGoogleMapsLocation } from "@src/appV2/Location/utils";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";

export function useWorkerLocations() {
  const workerGeoLocation = useWorkerGeoLocation();

  const { data: isDeviceLocationPermissionGranted } = useIsDeviceLocationPermissionGranted();
  const { data: deviceGeoLocation } = useDeviceGeoLocationIfAllowed({
    enabled: !!isDeviceLocationPermissionGranted,
  });

  const shouldRequestLocationPermission =
    !deviceGeoLocation && !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  const homeLocation = isDefined(workerGeoLocation)
    ? convertToGoogleMapsLocation(workerGeoLocation)
    : undefined;

  const deviceLocation = isDefined(deviceGeoLocation?.geoLocation)
    ? convertToGoogleMapsLocation(deviceGeoLocation.geoLocation)
    : undefined;

  const workerLocation = deviceLocation ?? homeLocation;

  return {
    workerLocation,
    deviceLocation,
    homeLocation,
    shouldRequestLocationPermission,
  };
}
