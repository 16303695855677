import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";

import { OpenShiftCard } from "../../Shift/Open/Card";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import { useOpenShiftListDataContext } from "../../Shift/Open/useOpenShiftListDataContext";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { ShiftVirtualizedList } from "../../Shift/Open/VirtualizedList";
import { PriorityShiftsEmptyState } from "./EmptyState";

interface PriorityShiftsListProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

export function PriorityShiftsList(props: PriorityShiftsListProps) {
  const { scrollRef } = props;

  const { listRef, virtualShiftListItems } = useVirtualShiftListContext();

  const isListEmpty = virtualShiftListItems.length === 0;

  const {
    onClickOpenShift,
    workplacesMap,
    shiftsMissingRequirementsMap,
    isOpenShiftsLoading,
    isOpenShiftsError,
    isOpenShiftsSuccess,
  } = useOpenShiftListDataContext();

  if (isOpenShiftsError) {
    return <Text align="center">Error loading priority shifts</Text>;
  }

  if (isOpenShiftsLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying shifts",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isOpenShiftsSuccess && isListEmpty) {
    return <PriorityShiftsEmptyState />;
  }

  return (
    <ShiftVirtualizedList
      ref={listRef}
      scrollRef={scrollRef}
      items={virtualShiftListItems}
      renderItem={(shift) => {
        const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
        if (!isDefined(workplace)) {
          return null;
        }

        const { missingDocuments, showMissingDocumentsCount, isInstantReview } =
          shiftsMissingRequirementsMap.get(shift.id) ?? {};

        return (
          <OpenShiftCard
            key={shift.id}
            workers={[]}
            shift={shift}
            workplace={workplace}
            missingDocuments={missingDocuments ?? []}
            showMissingDocumentsCount={showMissingDocumentsCount ?? false}
            isInstantReview={isInstantReview ?? false}
            onClick={({ offer }) => {
              onClickOpenShift({ shift, workplace, offer });
            }}
          />
        );
      }}
    />
  );
}
