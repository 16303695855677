import { isDefined } from "@clipboard-health/util-ts";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import { parseISO } from "date-fns";

import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { isPriorityShift } from "../../Shift/Priority/isPriorityShift";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

export function usePriorityShiftsData() {
  const { dates: dateStrings, distance } = useShiftDiscoveryUserFiltersContext();
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const workerGeoLocation = useWorkerGeoLocation();

  const { shifts, ...rest } = useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      area: {
        latitude: workerGeoLocation?.latitude ?? 0,
        longitude: workerGeoLocation?.longitude ?? 0,
        radiusInMiles: distance,
      },
    },
    enabled: isDefined(workerGeoLocation),
  });

  const priorityShifts = shifts.filter((shift) =>
    isPriorityShift({
      priorityTill: shift.attributes.priorityTill,
      window: shift.attributes.window,
    })
  );

  return {
    ...rest,
    shifts: priorityShifts,
  };
}
