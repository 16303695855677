import { isDefined } from "@clipboard-health/util-ts";
import { groupBy } from "lodash";

import { type OpenShift } from "../../Shift/Open/types";
import { type Workplace } from "../../Workplace/types";
import { type MapViewWorkplace } from "./types";

export function getMapViewWorkplaces(parameters: {
  openShifts: OpenShift[];
  openShiftWorkplacesMap: Map<string, Workplace>;
  onCallWorkplaceIds: Set<string>;
}) {
  const { openShifts, openShiftWorkplacesMap, onCallWorkplaceIds } = parameters;

  const mapViewWorkplacesMap = new Map<string, MapViewWorkplace>();
  const openShiftsByWorkplaceId = groupBy(openShifts, "relationships.workplace.data.id");

  function setMapViewWorkplace(workplace: Workplace) {
    const { id: workplaceId, attributes } = workplace;
    const { location } = attributes;

    const isOnCallWorkplace = onCallWorkplaceIds.has(workplaceId);

    // Don't add on call workplaces to the map.
    if (isOnCallWorkplace) {
      return;
    }

    // Sanity check: Can't add workplaces that don't have a location to the map.
    if (!isDefined(location) || (location.latitude === 0 && location.longitude === 0)) {
      return;
    }

    const workplaceOpenShifts = openShiftsByWorkplaceId[workplaceId];

    // Only add workplaces with open shifts to the map.
    if ((workplaceOpenShifts?.length ?? 0) > 0) {
      mapViewWorkplacesMap.set(workplaceId, {
        ...workplace,
        shiftsCount: workplaceOpenShifts.length,
      });
    }
  }

  // Can't use spread operator because our tsconfig targets ES5 and it doesn't support it.
  // TODO: Update tsconfig to target ES2015 or higher src/appV2/tsconfig.json
  // eslint-disable-next-line unicorn/prefer-spread
  Array.from(openShiftWorkplacesMap.values()).forEach((workplace) => {
    setMapViewWorkplace(workplace);
  });

  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(mapViewWorkplacesMap.values());
}
